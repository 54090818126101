import NativeScene from '@bycape/scenejs';
import { roundToNearestDecimal } from 'components/template-designer/utils/roundNumbers';

/**
 * Canvas helpers to store the Scene object and call most used methodes.
 */
class SceneHelpers {
    /**
     * Scene object holder.
     */
    static scene: NativeScene | null = null;

    /**
     * Get the Scene object holder.
     * @returns Scene object holder.
     */
    static getScene(): NativeScene | null {
        return this.scene;
    }

    /**
     * Set the Scene object holder.
     * @param scene - Scene object.
     */
    static setScene(scene: NativeScene): void {
        this.scene = scene;
    }

    /**
     * Pause Scene.
     */
    static pauseScene(): void {
        if (!this.scene) return;
        this.scene.pause();
    }

    /**
     * Play Scene.
     */
    static playScene(): void {
        if (!this.scene) return;
        this.scene.play();
    }

    /**
     * Get Scene time.
     * @returns Scene time.
     */
    static getSceneTime(duration: number): number {
        if (!this.scene) return 0;
        const time = this.scene.getTime();

        // If the time is greater than the duration, return the correct time.
        const correctTime = time % duration;
        return roundToNearestDecimal(correctTime, 100);
    }

    /**
     * Set Scene time.
     * @param time - Time to set.
     */
    static setSceneTime(time: number): void {
        if (!this.scene) return;
        // Minus a small number because in scene if the current time is equal to the end time the first frame will be shown.
        this.scene.setTime(time - 0.00001);
    }
}

export { SceneHelpers };
